import * as React from 'react'
import { useEffect, useState } from 'react'

import { Accordion, Card, Col, Container, ProgressBar, Row } from 'react-bootstrap'

// COMPONENTS
import Layout from 'layouts/main'

import '../styles/components.css'
import queryString from 'querystring'
import Api from '../helpers/api'
import { config } from '../config'
import ProfilePic from '../images/profile_placeholder.png'
import axios from 'axios'
import Loading from '../components/shared/loading'

const Project = () => {
  const [team, setTeam] = useState({})
  const [students, setStudents] = useState([])
  const [teamObjectives, setTeamObjectives] = useState(null)
  const [studentImages, setStudentImages] = useState({})
  const [accessDenied, setAccessDenied] = useState(false)

  useEffect(async () => {
    try {
      const token = window.localStorage.getItem('token')
      let user = (await axios.get(`${config.API_BASE_URL}/users/profile?token=${token}`)).data
      if (user.role === 'ROLE_TRUSTEE_LIMITED') {
        setAccessDenied(true)
      }
    } catch (e) {
      const { id } = queryString.parse(window.location.search.substr(1))
      window.localStorage.removeItem('user')
      window.localStorage.removeItem('token')
      window.location.href = `/?showLogin=true&next=/project?id=${id}`
    }

  }, [])

  useEffect(() => {
    const { id } = queryString.parse(window.location.search.substr(1))

    Api.getInstance()
      .getTeams()
      .then(response => {
        setTeam(response.find(t => t.id === id))
      })
      .catch(e => {
      })
      .finally(() => {
      })
    Api.getInstance()
      .getTeamStudents(id)
      .then(response => {
        setStudents(response)
      })
      .catch(e => {
      })
      .finally(() => {
      })
    Api.getInstance()
      .getTeamObjectives(id)
      .then(response => {
        setTeamObjectives(response)
      })
      .catch(e => {
      })
      .finally(() => {
      })
    Api.getInstance()
      .getStudentImages()
      .then(response => {
        setStudentImages(response)
      })
      .catch(e => {
      })
      .finally(() => {
      })
  }, [])

  useEffect(async () => {
    let progress = 0
    for (let i = 0; i < students.length; i++) {
      let student = students[i]
      progress += parseFloat(student.progress)
    }
    progress = parseInt(progress / students.length)
    setTeam(t => {
      return { ...t, progress }
    })
  }, [students])

  const shortNames = {
    'Product Management': 'PM',
    'Product Design': 'PD',
    'B2B Sales': 'B2B',
    'Full-Stack': 'FS',
    'Back-end': 'BE',
    'Digital Marketing': 'DM',
  }

  if (!teamObjectives) {
    return (
      <Layout title='Team'>
        <Container className='pb-5'>
          <link
            href='https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css'
            rel='stylesheet'
            integrity='sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3'
            crossOrigin='anonymous'
          ></link>
          <div>
            <Loading/>
          </div>
        </Container>
      </Layout>
    )
  }

  return (
    <Layout title='Team'>
      <Container className='pb-5'>
        <link
          href='https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css'
          rel='stylesheet'
          integrity='sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3'
          crossOrigin='anonymous'
        ></link>
        {accessDenied &&
        <div>You don't have permission to access this page.</div>
        }
        {!accessDenied &&

        <>
          <Row className='mt-5 mb-5 align-items-center'>
            <Col>
              <h3 className='mt-0 fw-medium'>{team.projectName}</h3>
              <p className='fs-5'>
                <a href={`/team?id=${team.id}`} className='text-dark  '>
                  {team.name}
                </a>
              </p>
            </Col>
            <Col md='3' className='mb-3 mb-md-0'>
              <Card className='shadow-xl border-0 rounded-xl text-center py-3 px-4'>
                <div className='d-flex mb-2 align-items-center'>
                  <ProgressBar
                    variant='warning'
                    now={team.progress}
                    className='mb-0 me-2 w-100 rounded-0'
                  />
                  <span className='align-middle'>{team.progress}%</span>
                </div>

                <p className='small mb-0'>TEAM PROGRESS</p>
              </Card>
            </Col>
            <Col md='auto'>
              <Card className='shadow-xl border-0 rounded-xl text-center py-3 px-4'>
                <dl className='row g-0 mb-0 justify-content-center'>
                  {students.map((student, index) => {
                    return (
                      <>
                        <dt className={`col-auto ${index > 0 ? 'ms-3' : ''}`}>
                          {shortNames[student.program] || student.program}:
                        </dt>
                        <dd className='col-auto mx-1'>
                          {parseInt(student.progress)}%
                        </dd>
                      </>
                    )
                  })}
                </dl>

                <p className='small mb-0'>TEAM PERFORMANCE</p>
              </Card>
            </Col>
          </Row>

          <br />

          <Row className='my-md-5 my-3 align-items-center g-0'>
            {students.map(student => {
              return (
                <Col
                  md='6'
                  lg='4'
                  className='mb-3 d-flex align-items-center'
                  key={`student-${student.id}`}
                >
                  <img
                    src={
                      studentImages[student.email]
                        ? `${config.CDN_BASE_URL}/${studentImages[student.email]}`
                        : ProfilePic
                    }
                    className='ms-1 me-3 rounded-circle float-start float-md-none'
                    style={{ width: 76, height: 76 }}
                    alt=''
                  />
                  <div>
                    <a
                      href={`/student?id=${student.id}`}
                      className='mt-0 fw-medium fs-20px text-dark'
                    >
                      {student.fullName}
                    </a>
                    <p className='fs-5'>{student.program}</p>
                  </div>
                </Col>
              )
            })}
          </Row>

          <h3 className='mt-5 pt-3 mb-4'>
            <strong>{teamObjectives.title}</strong>
          </h3>
          <p className='mb-5 pb-3'>{teamObjectives.description}</p>

          <Accordion
            defaultActiveKey='0'
            id='preformance-accordion'
            className='shadow-xl'
          >
            {teamObjectives.keyResults.map((keyResult, index) => {
              return (
                <Accordion.Item eventKey={index} key={`accordion-item-${index}`}>
                  <Accordion.Header>
                  <span className='d-flex flex-column flex-md-row align-items-md-center w-100'>
                    <span className='mw-50 w-50'>{keyResult.title}</span>
                    <span className='mt-md-0 mt-2 fw-bold mw-50'>
                    {keyResult.description}
                    </span>
                  </span>
                  </Accordion.Header>
                  <Accordion.Body className='p-0'>
                    <Accordion defaultActiveKey='2'>
                      {keyResult.bi_weekly.map((bi_weekly, bi_weekly_index) => {
                        return (
                          <Accordion.Item
                            eventKey={`bi_weekly${index}-${bi_weekly_index}`}
                            className='border-0'
                            key={`accordion-sub-item-${bi_weekly_index}`}
                          >
                            <Accordion.Header>
                            <span className='d-flex flex-column flex-md-row align-items-md-center w-100'>
                              <span className='mw-50 w-50'>{bi_weekly.title}</span>
                              <span className='mt-md-0 mt-2 fw-bold mw-50'>
                                {bi_weekly.description}
                              </span>
                            </span>
                            </Accordion.Header>
                            <Accordion.Body className='p-0'>
                              {bi_weekly.week.map((week, week_index) => {
                                if (!week.keyResult) {
                                  return <></>
                                }
                                let completed = week.keyResult.filter(
                                  keyResult => keyResult.done,
                                ).length
                                let total = week.keyResult.length

                                return (
                                  <>
                                    <div className='bg-gray text-center p-3'>
                                      {week.title}
                                    </div>
                                    <Row className='p-5 flex-column-reverse flex-md-row'>
                                      <Col>
                                        {week.description}

                                        {/*{week.keyResult.map(keyResult=>{
                                return <div>{keyResult.title} - <span className={keyResult.done ? 'text-success':'text-danger'}>{keyResult.done ? "Done":"Not Done"}</span></div>
                              })}*/}
                                      </Col>
                                      <Col
                                        sm='auto'
                                        className='text-center mb-4 mb-md-0'
                                      >
                                        <h5 className='text-success mb-0 fw-bold'>
                                          {(total > 0
                                              ? (completed / total) * 100
                                              : 0
                                          ).toFixed(0)}
                                          %&nbsp;
                                          <br className='d-none d-md-block' />
                                          Complete&nbsp;
                                        </h5>
                                      </Col>
                                    </Row>
                                  </>
                                )
                              })}
                            </Accordion.Body>
                          </Accordion.Item>
                        )
                      })}
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              )
            })}
          </Accordion>
        </>
        }
      </Container>
    </Layout>
  )
}

export default Project
